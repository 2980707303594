import SchoolsList from '@/api/administration/schools_list/schools_list'
import fileDownload from 'js-file-download'

const schools_list = new SchoolsList()

const SchoolsListModule = {
  namespaced: true,
  state: {
    item: null,
    items: [],
    total: 0,
    lookups: null,
    load: false,
  },
  getters: {
    items(state) {
      return state.items
    },
    item(state) {
      return state.item
    },
    load(state) {
      return state.load
    },
    total(state) {
      return state.total
    },
    status(state) {
      return state.item.removed
    },
    lookups(state) {
      return state.lookups
    },
  },
  mutations: {
    SET_ITEMS: (state, items) => {
      state.items = items
    },
    SET_TOTAL_ITEMS: (state, total) => {
      state.total = total
    },
    SET_ITEMS_LOAD: (state, load) => {
      state.load = load
    },
    SET_ITEM: (state, item) => {
      state.item = item
    },
    SET_LOOKUPS(state, lookups) {
      state.lookups = lookups
    },
  },
  actions: {
    list({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true)
        schools_list.list(payload.query).then(response => {
          commit('SET_ITEMS', response.data)
          commit('SET_TOTAL_ITEMS', response.meta.count)
          commit('SET_ITEMS_LOAD', false)
          commit('SET_LOOKUPS', response.meta.lookup)
          resolve()
        })
      })
    },
 
    put({ commit }, payload) {
      commit('app/UPDATE_LOAD', true, { root: true })
      return new Promise((resolve, reject) => {
        schools_list.put(payload.id,payload.query)
          .then(response => {
            commit('app/UPDATE_LOAD', false, { root: true })
            resolve(response)
          }).catch(error => {
            commit('app/UPDATE_LOAD', false, { root: true })
            reject(error.response.data)
          })
      })
    },
    delete({ commit }, id) {
      return new Promise((resolve, reject) => {
        schools_list.delete(id).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
   
    export({commit},payload){
             return new Promise((resolve, reject) => {
               commit('SET_ITEMS_LOAD', true);
               schools_list
                 .export(payload)
                 .then(response => {
                   fileDownload(response,'schools_list.xlsx');
                   commit('SET_ITEMS_LOAD', false);
                   resolve(response);
                 })
                 .catch(error => {
                   reject(error);
                 });
             });
           }
      
   
  },
}

export default SchoolsListModule
