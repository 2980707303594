import Resource from '../../resource';
import request from '../../../utils/request';

class SpecializationsTime extends Resource {
    constructor() {
        super('specialization-time');
    }

    put(id, query) {
        return request({
          url: `/specialization-time${id ? `/${id}` : ``}`,
          method: 'put',
          data: query,
 
        })
      }
      get(id) {
        return request({
            url: `/specialization-time/${id}`,
            method: 'get',
        });
    }
     
    specializationsTimelist(query) {
        return request({
            url: `/specialization-time/list`,
            method: 'POST',
            data: query,
        })
    }
    approve(id, query) {
        return request({
            url: `/specialization-time/distribute/${id}`,
            method: 'POST',
            data: query,
        })
    }

        export({payload,programId}) {
            const url = `/specialization-time/export-students-data/${payload.bylaw.id}/${programId}`;
            return request({
                url: url, 
                method: 'post', 
                responseType: 'blob', 
                params: {
                    start_date: payload.start_date,
                    end_date: payload.end_date
                  }
            });
        }

    
        preview(payload) {
            return request({
              url: `/specialization-time/preview/${payload.bylaw.id}`,
              method: 'get',
              params: {
                start_date: payload.start_date,
                end_date: payload.end_date
              }
            });
          }
          
    lookups( payload ) {
        return request({
            url: `/specialization-time/lookups`,
            method: 'get',
            data: payload
        });
    }
    // lookup(payload) {
    //     return request({
    //         url: `/lookups/get`,
    //         method: 'post',
    //         data: payload
    //     });
    // }

}

export {SpecializationsTime as default};
