import Resource from '../../../api/resource';
import request from '../../../utils/request';

class PlacementTest extends Resource {
    constructor() {
        super('placement_test');
    }

    list(payload) {
        return request({
            url: `/placement_test/list`,
            method: 'get',
            params: payload.query
        });
    }

    item(id) {
        return request({
            url: `/placement_test/item/${id}`,
            method: 'get',
        });
    }

    edit(payload) {
        let url = payload.id ? `/placement_test/edit/${payload.id}` : `/placement_test/edit`;
        return request({
            url: url,
            method: 'post',
            data: payload.query
        });
    }

    remove(id) {
        return request({
            url: `/placement_test/delete/${id}`,
            method: 'delete',
        });
    }

    lookups(payload) {
        return request({
            url: `/placement_test/lookups`,
            method: 'get',
            params: payload,
        });
    }

    placementTests(payload) {
        return request({
            url: `/placement_test/placement_tests/${payload.model}/${payload.id}`,
            method: 'get',
        });
    }

    updatePlacementTests(payload) {
        return request({
            url: `/placement_test/update_placement_tests/${payload.model}/${payload.id}`,
            method: 'post',
            data: payload
        });
    }

    createMissingPlacementTests(termId) {
        return request({
            url: `/placement_test/create_missing_placement_tests/${termId}`,
            method: 'post'
        });
    }
}

export {PlacementTest as default};
