import PlacementTest from '../../../../api/academic_settings/placement_test/placement_test';
import fileDownload from 'js-file-download'
import router from "@/router";

const placementTest = new PlacementTest();

const placementTestModule = {
  namespaced: true,
  state: {
    item: {},
    items: [],
    total: 0,
    lookups: null,
    load: false,
  },
  getters: {
    items(state) {
      return state.items;
    },
    item(state) {
      return state.item;
    },
    load(state) {
      return state.load;
    },
    total(state) {
      return state.total;
    },
    lookups(state) {
      return state.lookups;
    },
  },
  mutations: {
    SET_ITEMS: (state, items) => {
      state.items = items;
    },
    SET_TOTAL_ITEMS: (state, total) => {
      state.total = total;
    },
    SET_ITEMS_LOAD: (state, load) => {
      state.load = load;
    },
    SET_ITEM: (state, item) => {
      state.item = item;
    },
    SET_LOOKUPS(state, lookups) {
      state.lookups = lookups;
    },
  },
  actions: {
    list({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        placementTest
          .list(payload)
          .then(response => {
            commit('SET_ITEMS', response.data);
            commit('SET_TOTAL_ITEMS', response.meta.count);
            commit('SET_LOOKUPS', response.meta.lookup);
            commit('SET_ITEMS_LOAD', false);
            resolve();
          })
          .catch(error => {
            commit('SET_ITEMS_LOAD', false);
            reject(error);
          });
      });
    },

    edit({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        placementTest
          .edit(payload)
          .then(response => {
            commit('SET_ITEMS_LOAD', false);
            resolve(response);
          })
          .catch(error => {
            commit('SET_ITEMS_LOAD', false);
            reject(error);
          });
      });
    },

    item({commit}, id) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        placementTest
          .item(id)
          .then(response => {
            commit('SET_ITEM', response.data);
            commit('SET_ITEMS_LOAD', false);
            resolve(response);
          })
          .catch(error => {
            commit('SET_ITEMS_LOAD', false);
            reject(error);
          });
      });
    },

    remove({commit}, id) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        placementTest
          .remove(id)
          .then(response => {
            commit('SET_ITEM', response.data);
            commit('SET_ITEMS_LOAD', false);
            resolve(response);
          })
          .catch(error => {
            commit('SET_ITEMS_LOAD', false);
            reject(error);
          });
      });
    },

    lookups({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        placementTest
          .lookups(payload)
          .then(response => {
            commit('SET_ITEM', response.data);
            commit('SET_ITEMS_LOAD', false);
            resolve(response);
          })
          .catch(error => {
            commit('SET_ITEMS_LOAD', false);
            reject(error);
          });
      });
    },

    placementTests({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        placementTest
          .placementTests(payload)
          .then(response => {
            commit('SET_ITEM', response.data);
            commit('SET_ITEMS_LOAD', false);
            resolve(response);
          })
          .catch(error => {
            commit('SET_ITEMS_LOAD', false);
            reject(error);
          });
      });
    },

    updatePlacementTests({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        placementTest
          .updatePlacementTests(payload)
          .then(response => {
            commit('SET_ITEM', response.data);
            commit('SET_ITEMS_LOAD', false);
            resolve(response);
          })
          .catch(error => {
            commit('SET_ITEMS_LOAD', false);
            reject(error);
          });
      });
    },

    createMissingPlacementTests({commit}, termId) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        placementTest
          .createMissingPlacementTests(termId)
          .then(response => {
            commit('SET_ITEMS_LOAD', false);
            resolve(response);
          })
          .catch(error => {
            commit('SET_ITEMS_LOAD', false);
            reject(error);
          });
      });
    },

  },
};

export default placementTestModule;
